const state = {
  balance: null,
  isLoadingBalance: false,
  name: "",
  icon: "",
};

const getters = {
  pointsName: (state) => state.name,
  pointsIcon: (state) => state.icon,
  pointsBalance: (state) => state.balance,
  pointsIsLoadingBalance: (state) => state.isLoadingBalance,
};

const POINTS_FETCH_BALANCE_STARTED = "POINTS_FETCH_BALANCE_STARTED";
const POINTS_FETCH_BALANCE_SUCCEED = "POINTS_FETCH_BALANCE_SUCCEED";
const POINTS_FETCH_BALANCE_FAILED = "POINTS_FETCH_BALANCE_FAILED";

const actions = {
  pointsFetchBalance({ commit }) {
    commit(POINTS_FETCH_BALANCE_STARTED);

    this._vm.axios
      .get("/points/balance/" + process.env.VUE_APP_STOREFRONT_ID)
      .then(({ data }) => {
        commit(POINTS_FETCH_BALANCE_SUCCEED, data);
      })
      .catch(() => {
        commit(POINTS_FETCH_BALANCE_FAILED);
      });
  },
};

const mutations = {
  [POINTS_FETCH_BALANCE_STARTED](state) {
    state.isLoadingBalance = true;
  },
  [POINTS_FETCH_BALANCE_SUCCEED](state, data) {
    state.balance = data.balance;
    state.name = data.currency_name;
    state.icon = data.currency_icon;
    state.isLoadingBalance = false;
  },
  [POINTS_FETCH_BALANCE_FAILED](state) {
    state.isLoadingBalance = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
